import React, { Component } from 'react'

export class tickets extends Component {

    componentDidMount() {
        window.location.href = "https://secure.givelively.org/event/found-stages-inc/frankenstein-s-funeral/labor-day-cookout-and-fundraiser"
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default tickets
